<template>
  <section class="company text-center my-5">
    <b-container>
      <b-row>
        <b-col md="4">
          <Chart />
        </b-col>
        <b-col md="8" class="company__title">
          <p class="description" v-html="investmentDescription"></p>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Company",
  components: {
    Chart: () => import("./Chart")
  },
  props: {
    investmentDescription: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped></style>
